import { Component, Input, SecurityContext, ViewEncapsulation } from '@angular/core';
import { CCUALERTService, SelectedBuildingService } from '@shared/_services';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import {
    ConfirmModalComponent,
    AuthenticationService, MessageService, SiteService,
    HelperService, FeatureToggleService, HeatmapService
} from '@75f/portal-ui-components';
import { AboutComponent } from '../about/about.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
declare var firebase;

@Component({
    selector: 'fs-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TopbarComponent {
    helpCenter: any;
    @Input() isExpanded?: boolean;
    showPAM: boolean;
    showCustomDashboards: boolean;
    showNamedSchedules: boolean;
    showSiteSequencer: boolean;
    allowEasyStreetAccess: boolean;
    siteName: string = '';

    @Input()
    get isDashboardPage(): boolean {

        return this._isDashboardPage;
    }
    set isDashboardPage(val: boolean) {
        this._isDashboardPage = val;
    }
    _isDashboardPage: boolean = false;
    buildingName: any;
    userName: string = '';
    totalAlertCount: any;
    isVofm = false;
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        public messageService: MessageService,
        public authService: AuthenticationService,
        private siteService: SiteService,
        public ccuAlertService: CCUALERTService,
        public router: Router,
        private helperService: HelperService,
        public dialog: MatDialog,
        private dom: DomSanitizer,
        public featureToggleService: FeatureToggleService,
        private heatmapService: HeatmapService,
        public selectedBuildingService: SelectedBuildingService
    ) { }

    ngOnInit() {
        this.isVofm = this.authService.isUserViewOnly();
        this.helpCenter = environment.helpCenter;
        let loggedInUserDetails = this.authService.getLoggedInUserDetails();
        if (loggedInUserDetails && loggedInUserDetails.firstName) {
            this.userName = loggedInUserDetails.firstName + " " + loggedInUserDetails.lastName;
        } else {
            this.authService.fetchLoggedInUserDetails().pipe(takeUntil(this.unsubscribe)).subscribe(_ => {
                let loggedInUserDetails = this.authService.getLoggedInUserDetails();
                if (loggedInUserDetails && loggedInUserDetails.firstName) {
                    this.userName = loggedInUserDetails.firstName + " " + loggedInUserDetails.lastName;
                }
            })
        }
        let buildingDetails = JSON.parse(localStorage.getItem('SelectedSite'));
        if (buildingDetails && !this._isDashboardPage) {
            this.getBuildingDetails(buildingDetails);
        }

        this.ccuAlertService.alertCountEvent.subscribe((receivedValues) => {
            this.totalAlertCount = receivedValues.list.totalAlertCount;

        });

        this.ccuAlertService.refreshAlertCountEvent.subscribe((param) => {
            this.refetchAlertCount();

        });

        this.featureToggleService.flagChange.pipe(takeUntil(this.unsubscribe)).subscribe((flags) => {
            this.setFlags(flags);
        })
        this.useFeatureToggles();
    }

    useFeatureToggles() {
        let sub = this.featureToggleService.featureFlagsSubject.pipe(takeUntil(this.unsubscribe)).subscribe((flags) => {
            this.setFlags(flags);
            sub.unsubscribe();
        });
        this.featureToggleService.getFlags();
    }

    setFlags(flags) {
        this.showPAM = flags.hasOwnProperty('pam') ? flags['pam'].value : false;
        this.showNamedSchedules = flags.hasOwnProperty('named-schedules') ? flags['named-schedules'].value : false;
        this.showSiteSequencer = flags.hasOwnProperty('site-sequencer') ? flags['site-sequencer'].value : false;
        this.showCustomDashboards = flags.hasOwnProperty('custom-dashboard') ? flags['custom-dashboard'].value : false;
        this.allowEasyStreetAccess = flags.hasOwnProperty('easy-street') ? flags['easy-street'].value : false;
    }

    // Gets the udpated site data from the silo
    getBuildingDetails(localSiteData) {
        this.siteService.getReadById(localSiteData?.siteId)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe({
                next: ({ rows }) => {
                    const buildingDetails = rows;
                    if (buildingDetails && buildingDetails.length > 0) {
                        let siteName = buildingDetails[0]?.dis;
                        // If siteName is updated, then update the selected building in local storage
                        if (siteName !== localSiteData.siteName) {
                            const selectedBuilding = {
                                siteId: this.helperService.stripHaystackTypeMapping(buildingDetails[0]?.id),
                                orgName: buildingDetails[0]?.organization,
                                siteName: buildingDetails[0]?.dis
                            };
                            this.selectedBuildingService.setSelectedBuilding(selectedBuilding);
                        }
                        this.siteName = siteName;
                    }
                },
                error: (err) => {
                    //If API throws error, updates the siteName with the local data
                    this.siteName = localSiteData?.siteName;
                }
            });
    }

    /**
     * This method opens the sidebar based on isExpanded property.
     */
    openSidebar(): void {
        this.messageService.openSidebar(!this.isExpanded);
        this.isExpanded = !this.isExpanded;
        if (!this.isExpanded) {
            document.querySelector('.fs-sidebar').classList.remove('expand');
            let contentWidth = document.querySelector('.fs-content') as HTMLElement;
            contentWidth.style.marginLeft = '0px';
            contentWidth.style.width = 'calc(100% - 44px)';
        } else {
            document.querySelector('.fs-sidebar').classList.add('expand');
            let contentWidth = document.querySelector('.fs-content') as HTMLElement;
            contentWidth.style.width = 'calc(100% - 189px)';
            this.heatmapService.setupResizeListener();
        }
    }

    /**
    * This method helps to navigate to '/UserManagement'.
    */
    navigateToUserManagement() {
        const isSiteExists = this.messageService.getSitesExist();
        if (isSiteExists) {

            this.router.navigate(['/user-management']);

        } else {
            const dialogRef = this.dialog.open(ConfirmModalComponent, {
                panelClass: 'alert-popup',
                autoFocus: false,
                width: '420px'
            });
            let htmlContent = `<div>User has no buildings assigned</div>`;
            dialogRef.componentInstance.title = '';
            dialogRef.componentInstance.warningIconDisplay = false;
            dialogRef.componentInstance.type = 'dialog';
            dialogRef.componentInstance.confirmBtnText = 'OK';
            dialogRef.componentInstance.htmlContent = this.dom.sanitize(SecurityContext.HTML, htmlContent);
        }
    }


    /**
     * This method helps to navigate to '/portfolio-analytics-management'.
     */
    navigateToEnergyBoard() {
        const isSiteExists = this.messageService.getSitesExist();
        if (isSiteExists) {

            this.router.navigate(['/portfolio-analytics-management']);

        } else {
            const dialogRef = this.dialog.open(ConfirmModalComponent, {
                panelClass: 'alert-popup',
                autoFocus: false,
                width: '420px'
            });
            let htmlContent = `<div>User has no buildings assigned</div>`;
            dialogRef.componentInstance.title = '';
            dialogRef.componentInstance.warningIconDisplay = false;
            dialogRef.componentInstance.type = 'dialog';
            dialogRef.componentInstance.confirmBtnText = 'OK';
            dialogRef.componentInstance.htmlContent = this.dom.sanitize(SecurityContext.HTML, htmlContent);
        }
    }

    navigateToCustomDashboards() {
        const isSiteExists = this.messageService.getSitesExist();
        if (isSiteExists) {
            this.router.navigate(['/custom-dashboard']);
        } else {
            const dialogRef = this.dialog.open(ConfirmModalComponent, {
                panelClass: 'alert-popup',
                autoFocus: false,
                width: '420px'
            });
            let htmlContent = `<div>User has no buildings assigned</div>`;
            dialogRef.componentInstance.title = '';
            dialogRef.componentInstance.warningIconDisplay = false;
            dialogRef.componentInstance.type = 'dialog';
            dialogRef.componentInstance.confirmBtnText = 'OK';
            dialogRef.componentInstance.htmlContent = this.dom.sanitize(SecurityContext.HTML, htmlContent);
        }
    }


    /**
     * This method helps to navigate to '/alerts'.
     */
    navigateToAlerts() {
        let currentUrl = this.router.url;
        const isSiteExists = this.messageService.getSitesExist();
        if (isSiteExists) {
            if (currentUrl.includes('alerts')) {
                if (currentUrl.includes('?')) {
                    let queryParams = currentUrl.split('?')[1];
                    let paramArray = queryParams.split('=');
                    if (paramArray[0] == "allSite") {
                        let paramVal = paramArray[1] == "true" ? true : paramArray[1] == "false" ? false : undefined;
                        if (paramVal)
                            localStorage.removeItem('SelectedSite');
                        this.router.navigate(['/alerts'], { queryParams: { allSite: paramVal } });
                    }

                }
                else {
                    this.router.navigate(['/alerts']);
                }

            }
            else if ((currentUrl == '/') || (currentUrl == '/dashboard')) {
                localStorage.removeItem('SelectedSite');
                (<HTMLElement>document.getElementsByClassName('fs-global-topbar__userbuliding--name')[0]).innerText = '';
                this.router.navigate(['/alerts'], { queryParams: { allSite: true } });
            }
            else {
                this.router.navigate(['/alerts'], { queryParams: { allSite: false } });
            }
        } else {
            const dialogRef = this.dialog.open(ConfirmModalComponent, {
                panelClass: 'alert-popup',
                autoFocus: false,
                width: '420px'
            });
            let htmlContent = `<div>User has no buildings assigned</div>`;
            dialogRef.componentInstance.title = '';
            dialogRef.componentInstance.warningIconDisplay = false;
            dialogRef.componentInstance.type = 'dialog';
            dialogRef.componentInstance.confirmBtnText = 'OK';
            dialogRef.componentInstance.htmlContent = this.dom.sanitize(SecurityContext.HTML, htmlContent);
        }
    }
    /**
     * This method fetches the alert count if the sideId is vaid.
     */
    refetchAlertCount() {
        let siteId = JSON.parse(localStorage.getItem('SelectedSite'));
        let siteList = [];
        if (siteId) {
            siteList.push(this.helperService.stripHaystackTypeMapping(siteId.siteId).split(' ')[0]);
            let isFixed = "false";
            this.siteService.getAlertsCount({ siteIds: siteList, isFixed: isFixed }, false).pipe(takeUntil(this.unsubscribe)).subscribe
                ((alertCount) => {
                    this.totalAlertCount = (alertCount.severity['SEVERE'] || 0) + (alertCount.severity['MODERATE'] || 0) + (alertCount.severity['LOW'] || 0);
                    this.ccuAlertService.setTotalAlertCount(this.totalAlertCount);
                    this.ccuAlertService.setSelectedSites(siteList);
                    this.ccuAlertService.setAlertOf("SingleSite");

                });
        }
        else {
            this.totalAlertCount = undefined;
            this.siteService.getAuthSites().subscribe(data => {
                if (data.sites && data.sites.length > 0) {

                    siteList = data.sites.map(site => site.siteId.split('@')[1]);
                    let isFixed = "false";
                    this.siteService.getAlertsCount({ siteIds: siteList, isFixed: isFixed }, true).pipe(takeUntil(this.unsubscribe)).subscribe
                        ((alertCount) => {
                            this.totalAlertCount = (alertCount.severity['SEVERE'] || 0) + (alertCount.severity['MODERATE'] || 0) + (alertCount.severity['LOW'] || 0);
                            this.ccuAlertService.setTotalAlertCount(this.totalAlertCount);
                            this.ccuAlertService.setSelectedSites(siteList);
                            this.ccuAlertService.setAlertOf("AllSite");

                        });

                }
            });
        }
    }

    /**
     * This method helps to navigate to '/alerts'.
     */
    navigateToSiteSequencer() {
        const isSiteExists = this.messageService.getSitesExist();
        if (isSiteExists) {
            this.router.navigate(['/site-sequencer']);
        } else {
            const dialogRef = this.dialog.open(ConfirmModalComponent, {
                panelClass: 'alert-popup',
                autoFocus: false,
                width: '420px'
            });
            let htmlContent = `<div>User has no buildings assigned</div>`;
            dialogRef.componentInstance.title = '';
            dialogRef.componentInstance.warningIconDisplay = false;
            dialogRef.componentInstance.type = 'dialog';
            dialogRef.componentInstance.confirmBtnText = 'OK';
            dialogRef.componentInstance.htmlContent = this.dom.sanitize(SecurityContext.HTML, htmlContent);
        }
    }

    /**
     * This method called the logout method in AuthService
     */
    logout() {
        if (firebase && firebase.messaging()) {
            firebase.messaging().deleteToken().then((e) => {
                this.authService.logout();
            }).catch(() => {
                this.authService.logout();
            })
        } else {
            this.authService.logout();
        }
    }
    /**
     * This opens the MatDialog of AboutComponent.
     */
    openAboutInfo() {
        const dialogRef = this.dialog.open(AboutComponent, {
            panelClass: 'about-popup',
            // width: '285px'
        });
    }

    formatAlertsCount(count: number): string {
        if (count < 1000) {
            return count.toString();
        } else {
            const roundedNumber: string = (Math.floor(count / 100) / 10).toFixed(1);
            return `${roundedNumber}k`;
        }
    }


    navigateToNamedSchedule() {
        this.router.navigate(['/named-schedule']);
    }

    navigateToAlertConfiguration() {
        this.router.navigate(['/alert-config']);
    }

    navigateToSiteManager() {
        this.router.navigate(['/site-manager']);
    }

    navigateToAuditTrail() {
        this.router.navigate(['/audit-trail'])
    }

    navigateToEnergyConfiguration() {
        this.router.navigate(['/energy-configuration'])
    }

    navigateToEasyStreet() {
        this.router.navigate(['/easy-street'])
    }

    checkCertificationAccess(view): boolean {
        return this.authService.isUserAllowedToCheck(view);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
