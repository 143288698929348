<div class="metrics-container">
    <div class="metrics-header">
        <div class="title-container">
            <h3 class="title">Metrics</h3>
            <p class="sub-title">Run diagnostic tests on each CCU to ensure optimal performance.</p>
        </div>
        <div class="spacer"></div>
        <div class="action-container">
            <button class="run-all-tests-button" mat-button color="primary" id="run-all-tests" (click)="runAllTests()">
                <div class="button-label">RUN ALL CCUs</div>
            </button>
        </div>
    </div>
    <div class="metrics-table-container">
        <!-- Actual Table  -->
        <table class="fs-metrics-table full-width-table">
            <thead class="fs-metrics-table__head">
                <tr class="fs-metrics-table__row head-row">
                    <th class="fs-metrics-table__cell head-cell">
                        <div class="fs-metrics-table__cell__data head-cell-data text-truncate">CCU Name</div>
                    </th>
                    <th class="fs-metrics-table__cell head-cell">
                        <div class="fs-metrics-table__cell__data head-cell-data text-truncate">Score</div>
                    </th>
                    <th class="fs-metrics-table__cell head-cell" *ngIf="isValidCertificationLevel">
                        <div class="fs-metrics-table__cell__data head-cell-data text-truncate">Run</div>
                    </th>
                </tr>
            </thead>
            <tbody class="fs-metrics-table__body" *ngIf="!isLoading">
                <tr class="fs-metrics-table__row" *ngFor="let row of ELEMENT_DATA">
                    <td class="fs-metrics-table__cell" *ngFor="let column of columnsToDisplay">
                        <div class="fs-metrics-table__cell__data body-cell-data text-truncate">{{ row[column] || '' }}</div>
                    </td>
                    <td class="fs-metrics-table__cell icon-cell" *ngIf="isValidCertificationLevel">
                        <img class="fs-metrics-table__cell__data run-button body-cell-data" src="./assets/images/run-action.svg" alt="Run Test" 
                        (click)="runTest(row['ccuName'])"/>
                    </td>
                </tr>
            </tbody>
        </table>

        <!-- Skeleton Loader -->
        <div *ngIf="isLoading" class="skeleton-loader-container">
            <table class="full-width-table">
                <tbody>
                    <tr *ngFor="let row of skeletonRows">
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </tr>
                </tbody>
            </table>
        </div>
    
        <div class="gradient-overlay"></div>
    </div>
</div>