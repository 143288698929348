import { AlertService, ArrayUtil, HelperService } from '@75f/portal-ui-components';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild ,OnDestroy, ElementRef, Output, EventEmitter } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EasyStreetService } from '../../services/easystreet.service';
import { catchError, EMPTY, switchMap, throwError } from 'rxjs';


@Component({
  selector: 'fs-easy-street-site-report',
  templateUrl: './easy-street-site-report.component.html',
  styleUrls: ['./easy-street-site-report.component.scss']
})
export class EasyStreetSiteReportComponent implements OnInit, OnChanges, OnDestroy {

  ccuList: any[] = [];
  selectedCCU: any = "";
  dataSource!: MatTableDataSource<any>;
  displayedColumns!: string[];
  originalTableData: any[] = [];
  placeholderMessage: any;
  alertLogFiles: any[] = [];
  timezone: any;
  tz!: any;
  selectCCUplaceholder = 'Select CCU';
  ccuValidantionMsg = 'No CCU found';
  alertLogsSubscription: any;
  alertLogsRefreshTime: number = 60000;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  punchListHeaders = [ 'Header 1', 'Header 2', 'Header 3', 'Header 4', 'Header 5', 'Header Header 6', 'Header Header 7', 'Header Header 8', 'Header Header 9', 'HeaderHeader 10',  'Header Header 11', 'Header Header 12', 'HeaderHeader 13',  'Header Header 14', 'Header Header 15', 'HeaderHeader 16'];
  isPunchList = true;
  dataIsLoading = true;
  totalCCUScore :any[] = [];
  punchList = [
    {
      status: "FAIL",
      description: "Zone temp check",
      affectedEquips : ["L01-CCU-AHU-1 - VAV Staged RTU-1","L01-CCU-AHU-1 - VAV Staged RTU-2","L01-CCU-AHU-1","L01-CCU-AHU-2","L01-CCU-AHU-3","L01-CCU-AHU-4","L01-CCU-AHU-5","L01-CCU-AHU-6"],
    },
    {
      status: "FAIL",
      description: "CO2 Response Check",
      affectedEquips: ["L01-CCU-AHU-1","L01-CCU-AHU-2","L01-CCU-AHU-3","L01-CCU-AHU-4","L01-CCU-AHU-5","L01-CCU-AHU-6"]
    }
  ];
  selectedIndex: number = 0;
  ccuCheckListData:any [] = [];
  @Input() ccuDetails: any;
  @Input() siteId: boolean;
  @Input() siteCheckListData: any = {};
  @Output() sortEquipPreferChange: any = new EventEmitter();
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  ccuData: any = [];
  isSortByEquip: boolean = false;
  punchListData: any = {};
  totalStats: any = {};
  siteHasScore: any = false;

  constructor(private helperService: HelperService,private easyStreetService:EasyStreetService, private alertService: AlertService,) { }
 

  /**On changes of ccuDetails, siteCheckListData and siteId in easy-metric-layout, this method will be triggered
   * site score,punchlist and loader status are set here*/
  ngOnChanges(changes:SimpleChanges) {
    if(changes?.ccuDetails?.currentValue) 
    this.ccuData = [{ccuName:'Punch List',isPunchList:true},...changes?.ccuDetails?.currentValue];


    if(changes?.siteCheckListData?.currentValue && Object.keys(changes.siteCheckListData.currentValue).length !== 0) {
    this.siteHasScore = changes?.siteCheckListData?.currentValue?.punchList?.length > 0;
    this.punchListData = changes?.siteCheckListData?.currentValue?.punchList?.filter((item) => item.status === 'FAIL');
    const totalStats = changes?.siteCheckListData?.currentValue?.score?.statistics;
    this.totalCCUScore = [
        { name: 'Total Checks', value: totalStats?.total, class: 'total-checks' },
        { name: 'Passed', value: totalStats?.passed, class: 'passed' },
        { name: 'Failed', value: totalStats?.failed, class: 'failed' }
      ];
    this.dataIsLoading = false;
    }


    if(changes?.siteId?.currentValue) {
      //clear all data on site change
      this.ccuCheckListData = [];
      this.punchListData = [];
      this.selectedIndex = 0;
      this.isPunchList = true;
      this.dataIsLoading = true;
      this.totalCCUScore = [];
    }
  }

  ngOnInit() {

  }

  /**Handles the API call for fetching ccu-checkout report for both
   * sort and non-sort by equip
   * Re-adjusts scroll based on selected ccus' position in UI.
   */
  selectCCU(index: number,event): void {
   this.selectedIndex = index;
    this.dataIsLoading = true;
   //adjust the scroll position in order to show the selected ccu in the middle of the screen 
   const target = (event.target as HTMLElement).closest('.punch-list-header');
    if (target) {
      target.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }

    if (!this.ccuData[index].isPunchList) {
      this.isPunchList = false;
      this.getCCUChecklistData(index);
    } else {
      this.isPunchList = true;
      this.sortEquipPreferChange.emit(this.isSortByEquip);
    }
  }

  /**Based on selected CCU api will be triggered
   * If user is in puch list, then site-level checkout will be fetched bu triggering sortEquipPreferChange.
   * If user is in CCU list, then CCU checkout will be fetched by triggering getCCUChecklistData.
   * @param event
   */
  onSortEquipChange(event) {
    this.isSortByEquip = event.checked;
    this.dataIsLoading = true;
    if(this.isPunchList) {
       this.sortEquipPreferChange.emit(this.isSortByEquip);
    } else {
      this.getCCUChecklistData(this.selectedIndex);
    }
  }

  /**API call to fetch CCU checkout report data based on selected CCU and based on sort by equip
   * @param index
   */
  getCCUChecklistData(index: number) {
    const ccuId = this.helperService.stripHaystackTypeMapping(this.ccuData[index].ccuId);
    // Get the runId for the selected CCU
    this.easyStreetService.getCCUCheckoutHistory(this.siteId, ccuId).pipe(
      switchMap((data: any) => {
        if (!data || data.length === 0) {
          this.dataIsLoading = false;
          return EMPTY;
        }

        // By Default first runId from timeline array is selected
        return this.easyStreetService.getCCUCheckoutReportById(this.siteId,ccuId,data[0]?.runId,this.isSortByEquip);
      }),
      catchError((error) => {
        this.alertService.error(error.error ? error?.error?.error : 'Something went wrong, please try again');
        this.dataIsLoading = false;
        return throwError(() => error);
      })
    ).subscribe({
      next: (finalData) => {
        this.siteHasScore = finalData?.report?.results?.length;
        //Format the root-cause and recommendation actions, as there can mbe multiple root-causes and recommendation actions
        if(finalData?.report?.results?.length) {
          if (!this.isSortByEquip) { 
            finalData.report.results = finalData.report.results.map(item => this.formatCCUDataRule(item));
          } else {
            finalData.report.results = finalData.report.results.map(entity => this.formatCCUDataEntity(entity));
          }
        }
        this.ccuCheckListData = ArrayUtil.deepFlatten(finalData?.report?.results);

        //Remove skipped test cases:
        this.ccuCheckListData = this.ccuCheckListData.filter((item) => item.status == 'FAIL' || item.status == 'PASS');

        //Sort the ccu-checklist data based on status(this is lexographically sorted,since Fail comes before pass)
        this.ccuCheckListData = this.ccuCheckListData.sort((a, b) => a.status.localeCompare(b.status));
        this.totalCCUScore = [
          { name: 'Total Checks', value: finalData?.report?.score?.statistics?.total, class: 'total-checks' },
          { name: 'Passed', value: finalData?.report?.score?.statistics?.passed, class: 'passed' },
          { name: 'Failed', value: finalData?.report?.score?.statistics?.failed, class: 'failed' }
        ];
        this.dataIsLoading = false;
      },
      error: (error) => {
        this.alertService.error(error.error ? error?.error?.error : 'Something went wrong, please try again');
        this.dataIsLoading = false;
      }
    });
  }

  /**If there are multiple root-causes and recommendation actions, then combine them into a  single string by adding ;
   * this method is called when sort by equip is false **/
  formatCCUDataRule(item) {
    const combinedRootCauses = item?.rootCauses?.reduce((acc, cause) => {
      acc?.description?.push(cause?.description);
      acc?.recommendedActions?.push(...cause?.recommendedActions);
      return acc;
    }, { description: [], recommendedActions: [] });
  
    return {
      ...item,
      rootCauses: {
        description: combinedRootCauses?.description?.length > 1
          ? combinedRootCauses?.description.join('; ')
          : combinedRootCauses?.description[0],
        recommendedActions: combinedRootCauses?.recommendedActions?.length > 1
          ? combinedRootCauses?.recommendedActions?.join('; ')
          : combinedRootCauses?.recommendedActions[0]
      }
    };
  }
  
    /**If there are multiple root-causes and recommendation actions, then combine them into a  single string by adding ;
   * this method is called when sort by equip is true **/
  formatCCUDataEntity(entity) {
    const updatedChecks = entity?.checks?.map(check => {
      const combinedRootCauses = check?.rootCauses?.reduce((acc, cause) => {
        acc?.description?.push(cause?.description);
        acc?.recommendedActions?.push(...cause?.recommendedActions);
        return acc;
      }, { description: [], recommendedActions: [] });
  
      return {
        ...check,
        rootCauses: {
          description: combinedRootCauses?.description.join('; '),
          recommendedActions: combinedRootCauses?.recommendedActions.join('; ')
        }
      };
    });
  
    return {
      ...entity,
      checks: updatedChecks
    };
  }
  

  
  

  ngOnDestroy() {
  }
  
}
