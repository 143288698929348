import { Component, OnInit, Inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ConfigurationService } from '@75f/portal-ui-components';

@Component({
  selector: 'fs-site-sequencer',
  templateUrl: './site-sequencer.component.html',
  styleUrls: ['./site-sequencer.component.scss']
})
export class SiteSequencerComponent implements OnInit {

  urlSafe: SafeResourceUrl;
  isLoading: boolean = true;
  seletedSite;

  constructor(private configService: ConfigurationService,
    private sanitizer: DomSanitizer,
    @Inject('LOCALSTORAGE') private localStorage: any) {}

  ngOnInit(): void {
    const appRoute = 'facilisight';
    const token = this.localStorage.getItem('bearer_token');
    const userData = JSON.parse(this.localStorage.getItem('user_data'));
    this.seletedSite = JSON.parse(this.localStorage.getItem('SelectedSite'));
    let siteSequencerUrl = this.configService.getConfig('siteSequencerUrl');
    let urlSafe = ''
    if (this.seletedSite) {
      urlSafe = `${siteSequencerUrl}?portal=${appRoute}&token=${token}&userId=${userData?.userId}&selectedSiteId=${this.seletedSite?.siteId}`
    } else {
      urlSafe = `${siteSequencerUrl}?portal=${appRoute}&token=${token}&userId=${userData?.userId}`
    }
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(urlSafe);
  }

}
