<div class="es-container">
    <span class="es-header">Easy Street</span>
    <span class="es-header-text">Access a streamlined overview of CCU test results and system health, enabling quick insights and efficient management of your site's performance</span>
    <div class="es-site-select p-t-4">
        <puc-select-dropdown [disableTranslation]="true" class="notranslate select-site" [placeholderLabel]="initSiteName" [(ngModel)]="selectedSite" [data]="sites" [displayKey]="'siteName'"
            [noEntriesFoundLabel]="loadingVal" [emptyResults]="isEmpty"  [valueKey]="'siteId'" (valueChanged)="selectSite($event)">
        </puc-select-dropdown>
    </div>

    <div class="es-content" [ngClass]="{'hide' : !siteSelected}">
        <div>
            <div>
                <fs-easy-street-gauge [siteScore]="siteScore" [isLoading]="isLoading" class="gauge-section"></fs-easy-street-gauge>
            </div>
            <div>
                <fs-easy-street-metrics [ccuData]="ccuData" [selectedSite]="selectedSite"></fs-easy-street-metrics>
            </div>
        </div>
        <div>
            <fs-easy-street-site-report [siteId]="selectedSite" [ccuDetails]="ccuData" [siteCheckListData]="siteCheckListData" (sortEquipPreferChange)="checkOutSiteDetails($event)"></fs-easy-street-site-report>
        </div>
    </div>
   
    <div class="messageContainer" [ngClass]="{'hide' : siteSelected}">
        <div class="messageIcon">
            <i class="fas fa-building fa-3x"></i>
        </div>
        <div class="messageIcon">Select a site to continue</div>
    </div>
</div>