import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SiteService, AlertService, ObjectUtil } from '@75f/portal-ui-components';
import { EasyStreetService } from '../../services/easystreet.service';
@Component({
  selector: 'fs-easy-street-layout',
  templateUrl: './easy-street-layout.component.html',
  styleUrls: ['./easy-street-layout.component.scss']
})
export class EasyStreetLayoutComponent implements OnInit {
  initSiteName: string = 'Select Site';
  selectedSite: string;
  siteSelected: boolean = false;
  sites: any[] = [];
  isEmpty = true;
  loadingVal = "Loading...";
  ccuData: any[] = [];
  isLoading = false;
  siteScore: any = null;
  private intervalId: any;
  siteCheckListData: any = {};

  constructor(private siteService: SiteService,
    private alertService: AlertService,
    public easyStreetService: EasyStreetService
  ) { }

  ngOnInit(): void {

    this.getAllSites();

    if (this.getSelectedSite()) {
      let selectedSite = this.getSelectedSite();
      this.initSiteName = selectedSite.siteName;
      this.selectedSite = selectedSite.siteId;
    }

    if (this.selectedSite) {
      this.getCCUList();
      this.checkOutSiteDetails();
    }
  }

  // Method to fetch data from the API By Interval
  fetchingDataOnInterval(): void {
  
    // Clear the existing interval, if any
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    // Start a new interval
    this.intervalId = setInterval(() => {
      this.checkOutSiteDetails();
      this.getCCUList();
    }, 60000);
  }
  
  // Method to check out site details
  checkOutSiteDetails(isSortByEquip?:boolean): void {
    const self = this;
    this.isLoading = true; // Set loading to true before API call
    this.easyStreetService.getSiteCheckOutDetails(this.selectedSite,isSortByEquip).subscribe({
      next: (response) => {
        this.siteScore = response?.score?.value == 'NaN' || response?.score?.value == null || response?.score?.value == undefined ? null : response?.score?.value;
        self.siteCheckListData = ObjectUtil.deepClone(response);
        this.isLoading = false;  // Set loading to false after receiving response
      },
      error: (err) => {
        this.alertService.error(err.error ? err.error.error : 'Something went wrong, please try again');
        this.isLoading = false; // Set loading to false on error
      }
    });
  }

  /**
   * Selects a site and initializes the selected site ID and site name.
   *
   * @param site - An array containing site information. The first element of the array
   *               is expected to have `sideId` and `siteName` properties.
   */
  selectSite(site: any) {
    const self = this;
    self.ccuData = [];
    self.siteCheckListData = {};
    this.selectedSite = site[0].siteId;
    this.initSiteName = site[0].siteName;
    this.siteSelected = true;
    this.siteScore = null;
    this.getCCUList();
    this.checkOutSiteDetails();
    //this.fetchingDataOnInterval();
  }

  /**
   * Fetches all authorized sites and updates the component's state accordingly.
   * 
   * This method performs the following actions:
   * - Initializes the `sites` array to an empty array.
   * - Subscribes to the `getAuthSites` observable from the `siteService`.
   * - If the response contains sites, it maps the `siteId` and `siteName` from the response to the `sites` array.
   * - Sets `isEmpty` to `false` and updates `loadingVal` to "No Results Found".
   * - If a site is stored in local storage under the key 'SelectedSite', it initializes `initSiteName` and `selectedSite` with the stored values.
   * - Logs any errors to the console.
   */
  getAllSites() {
    this.sites = [];
    this.siteService.getAuthSites().subscribe(data => {
      if (data?.sites?.length > 0) {
        this.sites = data.sites.map(({ siteId, siteName }) => ({ siteId: siteId.split('@')[1], siteName }));
        this.isEmpty = false;
        this.loadingVal = "No Results Found";
        if (this.getSelectedSite()) {
          let selectedSite = this.getSelectedSite();
          this.initSiteName = selectedSite.siteName;
          this.selectedSite = selectedSite.siteId;
          this.siteSelected = true;
        }
      }
    }, error => {
      this.alertService.error(error.error ? error.error.msg : 'Something went wrong, please try again');
    });
  }

  getSelectedSite() {
    return JSON.parse(localStorage.getItem('SelectedSite'));
  }

  getCCUList() {
    const self = this
    this.siteService.getCcus(this.selectedSite).subscribe(response => {
      const ccuData = response.rows.map((ccu: any) => { return { ccuId: ccu.id, ccuName: ccu.dis } })
      self.ccuData = [...ccuData];
    }, error => {
      this.alertService.error(error.error ? error.error.msg : 'Something went wrong, please try again');
    });
  }


  ngOnDestroy(): void {
    // Clear the interval to avoid memory leaks
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

}
