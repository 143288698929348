import {
	AlertService,
	HelperService,
	SiteService,
	RemoteInstallerOptionsSliderComponent,
	RemoteInstallerOptionsService, FeatureToggleService, AuthenticationService
} from '@75f/portal-ui-components';
import {
	Component,
	OnInit,
	ViewChild,
} from '@angular/core';
import { CCUALERTService } from '@shared/_services';
import { MatDialog } from '@angular/material/dialog';
import sortBy from 'lodash-es/sortBy';
import { Subject, takeUntil } from 'rxjs';
import { settingsMenu } from '@/modules/settings/menu-constant';
import { HelperServiceFacilisight } from '@/shared/_services/helper.service';
@Component({
    selector: 'fs-building-options',
    templateUrl: './building-options.component.html',
    styleUrls: ['./building-options.component.scss'],
})
export class BuildingOptionsComponent implements OnInit {

	@ViewChild('remoteInstaller', { static: false }) remoteInstallerOptionsSliderComponent: RemoteInstallerOptionsSliderComponent;

	showFloorPlan: boolean = true;
	siteRef: string;
	selectedFloor = '';
	submenus: any[] = settingsMenu;
	isSiteActive: Boolean = false;
	rooms: any[] = [];
	originalRooms: any[] = [];
	floors: any = [];
	displayOrientation: Boolean = false;
	showRemoteAccess: Boolean = false;
	private unsubscribe: Subject<void> = new Subject();
	constructor(
		public authService: AuthenticationService,
		public siteService: SiteService,
		public helperService: HelperService,
		public alertService: AlertService,
		public ccuAlertService: CCUALERTService,
		public remoteInstallerService: RemoteInstallerOptionsService,
		public dialog: MatDialog,
		public featureToggleService: FeatureToggleService, 
		public facHelperService:  HelperServiceFacilisight
	) {
	}

	ngOnInit(): void {
		this.helperService.clearData();
		let buildingDetails = JSON.parse(localStorage.getItem('SelectedSite'));
		this.siteRef = this.stripHayStackTags(buildingDetails.siteId).split(' ')[0];
		this.remoteInstallerOptionsSliderComponent?.setOptions();
		this.remoteInstallerService.setBuildingId(this.siteRef);
		this.useFeatureToggles();
	}

	stripHayStackTags(dataInput) {
		return this.helperService.stripHaystackTypeMapping(dataInput);
	}

	checkUserCertificationAccess(view) {
      return this.facHelperService.checkCertification(view,this.showRemoteAccess);
    }

    getPointData(pointRows) {
        let resPoints = [];
        pointRows.forEach(pointData => {
            if (pointData.building && pointData.displayUnit && pointData.point && pointData.writable) {
                resPoints.push({
                    name: 'displayUnit',
                    tags: ['building', 'displayUnit', 'writable', 'point'],
                    id: pointData.id
                })
            }
        });
        if (resPoints.length) {
            this.getDataFromPointIds(resPoints);
        }
    }

    getDataFromPointIds(pointsData) {
        pointsData.forEach(element => {
            this.helperService.assemblePointIdData(this.helperService.stripHaystackTypeMapping(element.id), 'write', element.name, '', 'update');
        });
        this.getUserSettingData();
    }

    getUserSettingData() {
        let pointCurrentData: any = {};
        this.helperService.getPointData().subscribe(res => {
            if (res) {
                pointCurrentData.displayUnit = { val: res.displayUnit.val, level: '16' }
            }
        });
    }

	useFeatureToggles() {
        let sub = this.featureToggleService.featureFlagsSubject.pipe(takeUntil(this.unsubscribe)).subscribe((flags) => {
            this.setFlags(flags);
            sub.unsubscribe();
        });
        this.featureToggleService.getFlags();
    }

    setFlags(flags) {
        this.showRemoteAccess = flags.hasOwnProperty('remote-ccu-access') ? flags['remote-ccu-access'].value : false;
    }



}
