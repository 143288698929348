<div class="gauge-container">
  <span class="gauge-header">Site Installation Score</span>
  <div *ngIf="isLoading" class="loader p-t-8">
    <img src="../../../../../assets/images/gauge_chart_loader.gif" alt="Loading..." />
  </div>
  <div *ngIf="!isLoading" class="p-t-20">
    <div echarts [options]="chartOptions" id="gauge-chart" [autoResize]="true" class="gauge-chart"
      (chartInit)="onChartInit($event)">
    </div>
  </div>
  <span class="gauge-footer">Installation score measures the success rate of HVAC device installations.</span>
</div>