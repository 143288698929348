import { Component, OnInit, ViewChild, HostListener, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { EnumService } from '@/shared/_services/enum.service';
import { Subject, map, takeUntil } from 'rxjs';
import {
    AlertService, LoaderService, UserService, AuthenticationService,
    SiteService, HelperService, TunersModalComponent, SmartGroupTableComponent, ArrayUtil, UnitService,FeatureToggleService, NotesService
} from '@75f/portal-ui-components';
import { AnalyticsService } from 'src/app/shared/_services/analytics.service';
import { settingsMenu } from '@/modules/settings/menu-constant';
import { HelperServiceFacilisight } from '@/shared/_services/helper.service';

@Component({
    selector: 'fs-tuners-apply',
    templateUrl: './tuners-apply.component.html',
    styleUrls: ['./tuners-apply.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TunersApplyComponent implements OnInit, OnDestroy {
    data: any[]= [];
    isHeaderFixed: boolean = false;
    showOtaCommands:any;
    types: any[] = [
        { 'label': 'Building', value: 'building' },
        { 'label': 'System', value: 'system' },
        { 'label': 'Zone', value: 'zone' },
        { 'label': 'Module', value: 'module' },
    ];
    showFloorPlan:boolean = true;
    isHighlighted = false;
    isTunersUpdated: boolean = false;
    columns: Array<any> = [
        {
            key: 'buildingName',
            label: 'Building Name'
        }, {
            key: 'address',
            label: 'Address'
        }, {
            key: 'noOfCCUS',
            label: 'No of CCUS'
        },
        {
            key: 'noOfZones',
            label: 'No of Zones'
        }
    ];
    submenus: any[] = settingsMenu;
    @ViewChild('sgTable', { static: false }) sgTable: SmartGroupTableComponent;
    ccuObj: any = [];
    versionDetails: any = [];
    buildings: any = {};
    showNotes:boolean = false;
    showRemoteAccess: boolean = false;
    showRmtAccess:boolean = false;
    @HostListener('window: scroll', ['$event'])
    onWindowScroll($event) {
        this.isHeaderFixed = (window.scrollY > 80) ? true : false;
    }
    confirmationModal: any = {
        active: false,
        fixed: false,
        width: 45,
        left: 0
    };
    selectedTuner: string;

    tuners: Array<any> = [

    ]
    payload: any;
    tunerlevels: Array<any> = [
        {
            label: 'Building',
            value: 'building'
        },
        {
            label: 'System',
            value: 'system'
        },
        {
            label: 'Zone',
            value: 'zone'
        },
        {
            label: 'Module',
            value: 'module'
        }
    ];
    siteId: any;
    subscriptions: any = {};
    tunerLevel: any = 'building';
    onTunerLevelChange$: Subject<any> = new Subject();
    isTunersUpdated$: Subject<any> = new Subject();
    private unsubscribeVersion: Subject<void> = new Subject();
    notesList = [];
    allCCU =[];
    allZones=[];
    filteredNotesList: any[];
    constructor(
        public siteService: SiteService,
        private authService: AuthenticationService,
        public helperService: HelperService,
        private featureToggleService: FeatureToggleService,
        public loaderService: LoaderService,
        private dialog: MatDialog,
        public alertService: AlertService,
        public enumService: EnumService,
        private userService: UserService,
        private unitService: UnitService,
        private analyticsService: AnalyticsService,
        public notesService: NotesService,
        public facHelperService: HelperServiceFacilisight
    ) { }

    ngOnInit() {
        const self = this;
        this.checkNotesCertificationAccess();

        const buildingDetails = JSON.parse(localStorage.getItem('SelectedSite'));
        const siteRef = buildingDetails?.siteId || '';
        this.siteId = this.stripHayStackTags(siteRef).split(' ')[0];
        self.getBuildingInfo(self.siteId);
        self.getTuners();
        self.getNotesList();
        this.useFeatureToggles();
    }

    ngAfterViewInit(){
        let loadTime = Date.now() - this.analyticsService.getPageLoadStartTime();
        this.analyticsService.pageLoadTime(loadTime);
    }

    /**
 *  This method returns false if not selected one /Building/System/Zone/Module to apply the edited tuner
 */
    closeconfirmationModal() {
        this.confirmationModal.active = false;
    }
    /**
 *  Here this method allows to edit the table data
 */
    edit(data) {
        const self = this;
        self.generatePayload(self.sgTable._data).then((_res) => {
            self.payload = _res;
            //  self.tunersList.openModal();
        })
    }
    stripHayStackTags(dataInput) {
        return this.helperService.stripHaystackTypeMapping(dataInput);
    }
     /**
 *  Here this method asks  reason for tuner change
 */

    tunerChange(event) {
        const self = this;
        self.onTunerLevelChange$.next(event);
        self.tunerLevel = event;
        self.getTuners();
        self.getNotesList();
    }

    useFeatureToggles() {
        
        let sub = this.featureToggleService.featureFlagsSubject.subscribe((flags)=>{
            this.setFlags(flags);
            sub.unsubscribe();
            
        });
        this.featureToggleService.getFlags();
    }

    setFlags(flags) {
        this.showOtaCommands = flags.hasOwnProperty('ota-commands-certificates') ? flags['ota-commands-certificates'].value: false;
        this.showRemoteAccess = flags.hasOwnProperty('remote-ccu-access') ? flags['remote-ccu-access'].value : false;
    }

     /**
 *  Here this method submit the tuner updated data with building , ccu, zone, module
 */
    async onTunerApply(data) {
        const self = this;

        const sgInfo = await self.generatePayload(self.sgTable._data);
        const tuners = data['tuners'].filter((_filterItem) => _filterItem).map((_item) => {
            const obj = {};
            obj['name'] = _item['name'];
            obj['tunerGroup'] = _item['tunerGroup'];
            return obj;
        });
        if (sgInfo['buildings'].length || sgInfo['ahuRefs'].length) {

            let refIds: any[] = [];
            if (self.tunerLevel == 'building') {
                refIds = sgInfo['buildings'];
            } else if (self.tunerLevel == 'system') {
                refIds = sgInfo['ahuRefs'];
            } else if (self.tunerLevel == 'zone') {
                refIds = sgInfo['zones']
            } else {
                refIds = sgInfo['modules']
            }

            let body = <any>{
                refIdList: refIds,
                tuners: tuners,
                tunerScope: self.tunerLevel.toUpperCase()
            }

            let pointers: any[] = await self.siteService.getTunerPointValues(body).toPromise();
            pointers = this.unitService.reformatPointer(pointers, data, false);
            const dialogRef = self.dialog.open(TunersModalComponent, {
                width: '70%',
                panelClass: 'tuner-modal-container',
                data: {
                    buildingsInfo: self.sgTable._data, tunerLevel: self.tunerLevel, tuners: data['tuners'],
                    pointers: pointers, pageType: 'building', siteRef:this.siteId
                }
            });
            dialogRef.updatePosition({ top: '50px' });
            const result = await dialogRef.afterClosed().toPromise();
            if (result && result == 'apply') {
                data['tuners'] = this.unitService.reFormatTuner(data, false)
                let pointerValues: any[] = await self.siteService.getTunerPointIds(body).toPromise();
                const priorityLevel = self.enumService.getEnum('priorityLevel');

                try {

                    pointerValues = pointerValues.map((_item) => {
                        const found =data['tuners'].find(tuner=> tuner['name'] == _item['tunerName'] && tuner['tunerGroup'].toUpperCase() == _item['tunerGroup'].toUpperCase());
                        //const found = ArrayUtil.findInArray(data['tuners'], 'name', _item['tunerName']);
                        _item['val'] = (found && found['newVal']) ? found['newVal'].toString() : '';
                        return _item;
                    })

                    const username = `web_${this.userService.getDisplayId()}`
                    const pointerValPayload = pointerValues.map((_item) => {
                        const obj = {};
                        obj['val'] = _item['val'];
                        obj['who'] = username;
                        obj['ref'] = _item['_id'];
                        obj['level'] = priorityLevel[self.tunerLevel]['level'];
                        obj['duration'] = '0ms';
                        return obj;
                    }).filter((_filterItem) => _filterItem && _filterItem['val']);
                    // commented temporarily
                    // const logPayload = pointerValues.map((_item) => {
                    //     const obj = {
                    //         pointId: _item['_id'],
                    //         pointName: _item['name'],
                    //         command: 'Update Point',
                    //         who: username,
                    //         val: _item['val'],
                    //         level: priorityLevel[self.tunerLevel]['level'],
                    //         reason: data.reason,
                    //         siteRef: _item['siteRef'] || '',
                    //         ahuRef: _item['ahuRef'] || ''
                    //     }
                    //     return obj;
                    // })
                    self.loaderService.active(true);
                    const res = await self.siteService.updateBulkWritablePointData(pointerValPayload, data.reason).toPromise();
                    self.isTunersUpdated$.next(true);

                    self.loaderService.active(false);
                    self.alertService.success('All changes have been saved');
                    // commented temporarily
                    // await self.siteService.createTunerLogs(logPayload).toPromise();
                } catch (err) {
                    console.log('err', err);
                    self.loaderService.active(false);
                    self.alertService.error('Something went wrong');
                }
            }
        } else {
            this.confirmationModal.active = true;
        }


    }

 /**
  *  Here this method generate or update the data entered on building, ccu, zone, system, module
  */
    generatePayload(data) {
        const self = this;

        return new Promise((resolve) => {
            const flatternRes = ArrayUtil.deepFlatten(data, 'children').filter((_item) => _item['selected']);
            // const sg = flatternRes.filter((_item) => (_item['type'] == 'smartgroup' && _item['_id'])).map((_mapItem) => _mapItem['_id']);
            const buildings = flatternRes.filter((_item) => (_item['type'] == 'building' && _item['_id'])).map((_mapItem) => _mapItem['_id']);
            const ccus = flatternRes.filter((_item) => (_item['type'] == 'system' && _item['_id'])).map((_mapItem) => _mapItem['_id']);
            const zones = flatternRes.filter((_item) => (_item['type'] == 'zone' && _item['_id'])).map((_mapItem) => _mapItem['_id']);
            const modules = flatternRes.filter((_item) => (_item['type'] == 'module' && _item['_id'])).map((_mapItem) => _mapItem['_id']);
            const ahuRefs = flatternRes.filter((_item) => (_item['type'] == self.tunerLevel && _item['ahuRef'])).map((_mapItem) => _mapItem['ahuRef'].replace(new RegExp('^@'), ''));
            const res = {
                // 'smartgroups': sg,
                'buildings': buildings,
                'ccus': ccus,
                'zones': zones,
                'modules': modules,
                'ahuRefs': ahuRefs
            }

            resolve(res);
        })

    }

    /**
     *
     * Here this method allows to edit building, zone, system, module to apply the edited tuner
     */
    editTuner(event) {
        const self = this;
        self.generatePayload(self.sgTable._data).then((_res) => {
            self.payload = _res;
        })


    }
    /**
     * Here this method gets tuners data
     */
    getTuners() {
        const self = this;
        self.tuners = [];
        self.loaderService.active(true);
        const params = {
            siteId: self.siteId
        }
        self.subscriptions['getTuners'] = self.siteService.getTuners(self.tunerLevel, params).subscribe((_res: any[]) => {
            self.loaderService.active(false);
            self.tuners = _res.filter((_filterItem) => _filterItem).map((_item) => {
                if (_item['name']) {
                    const names: Array<any> = _item['name'].split('-') || [];
                    _item['name'] = (names.length) ? names[names.length - 1] : _item['name'];
                    if(_item['unit'] && this.unitService.unitListArray.includes(_item.unit)) {
                        _item = this.unitService.formatTunerList(_item, false);
                    }
                }
                return _item;
            });
        }, () => {
            self.loaderService.active(false);
        })
    }

    /**
     *
     * Here this method adds all the building infomation in array
     */
    getBuildingInfo(siteId) {
        this.ccuObj = [];
        this.versionDetails = [];
        const self = this;
        self.subscriptions['getBuildings'] = self.siteService.getSiteTreeForTuners(siteId).subscribe(response => {
            let otherEquipQuery = `siteRef==@${this.siteId} and (equip and (oao or bypassDamper))`
            this.siteService.findByQuery(otherEquipQuery).subscribe(otherEquip => {
                otherEquip = this.helperService.stripHaystackTypeMapping(otherEquip.rows);
                response.children.forEach(ccuObj => {
                    ccuObj.otherEquip = otherEquip.filter(equip => `@${equip.ahuRef}` == ccuObj.ahuRef);
                });
                self.data = [response];
                self.ccuObj = response?.children.map(x => ({ ahuRef: x.ahuRef.replace(/@/g, ''), name: x.dis, _id: x._id }))
                this.setVersionData(this.siteId, self.ccuObj);
            });
        });
    }

    setVersionData(id,ccudata) {
        this.siteService.getEntitiesInBuilding(id).pipe(
            map(this.helperService.listEntities),
            map(this.helperService.createEntityStructure),
        ).subscribe(
            (response: any) => {
             this.setData(response,ccudata)
            
    }) 
}

    setData(res,ccudata) {
        this.buildings['equips'] = this.helperService.getBuildingComponents(res, 'equip');
        this.getccuVersion(ccudata);
    }

    getccuVersion(ccudata) {
        const diagnosticObj = this.getDiagObj(['equip', 'diag']);
        let versionRequests = [];
        let versionIdsToFetch = [];
        diagnosticObj.forEach(x => {
            // Fetching IDs directly to a separate array
            const ccuVersionId = this.helperService.getPointIdbyTags(x, ['app', 'diag', 'version'], null, null, 'appVersion')[0] || '';
            const bacAppVersionId = this.helperService.getPointIdbyTags(x, ['bacnet', 'diag', 'version'], null, null, 'bacnetAppVersion')[0] || '';
            const homeAppVersionId = this.helperService.getPointIdbyTags(x, ['home', 'diag', 'version'], null, null, 'homeAppVersion')[0] || '';
            const remoteAppVersionId = this.helperService.getPointIdbyTags(x, ['remoteAccess', 'diag', 'version'], null, null, 'remoteAccessAppVersion')[0] || '';
    
            versionRequests.push({
                equipId: x.equipId,
                ahuRef: x.referenceIDs.gateway
            });
            // Combine IDs for fetching
            versionIdsToFetch.push(ccuVersionId, bacAppVersionId, homeAppVersionId, remoteAppVersionId);
        });

        if (versionIdsToFetch.length) {
            this.siteService.getBulkWritablePointData(versionIdsToFetch).pipe(
                map(this.helperService.stripHaystackTypeMapping),
                takeUntil(this.unsubscribeVersion)
            ).subscribe(({ rows }) => {
                versionRequests.forEach(x => {
                    x['version'] = rows.find(y => y.id === this.helperService.getPointIdbyTags(diagnosticObj.find(z => z.referenceIDs.gateway === x.ahuRef), ['app', 'diag', 'version'], null, null, 'appVersion')[0] || '')?.data[0]?.val || 'NA';
                    x['bacAppVersion'] = rows.find(y => y.id === this.helperService.getPointIdbyTags(diagnosticObj.find(z => z.referenceIDs.gateway === x.ahuRef), ['bacnet', 'diag', 'version'], null, null, 'bacnetAppVersion')[0] || '')?.data[0]?.val || 'NA';
                    x['homeAppVersion'] = rows.find(y => y.id === this.helperService.getPointIdbyTags(diagnosticObj.find(z => z.referenceIDs.gateway === x.ahuRef), ['home', 'diag', 'version'], null, null, 'homeAppVersion')[0] || '')?.data[0]?.val || 'NA';
                    x['remoteAppVersion'] = rows.find(y => y.id === this.helperService.getPointIdbyTags(diagnosticObj.find(z => z.referenceIDs.gateway === x.ahuRef), ['remoteAccess', 'diag', 'version'], null, null, 'remoteAccessAppVersion')[0] || '')?.data[0]?.val || 'NA';
                });
                this.mapVersions(versionRequests,ccudata);
            });
        }
    }
   
    mapVersions(versionDetails,ccudata) {
        this.versionDetails = versionDetails
        const self = this;
        
        ccudata.forEach(x => {
            const matchedAhuRef = this.versionDetails.find(y => x.ahuRef == y.ahuRef);
            const ahuRefIndex = this.versionDetails.indexOf(matchedAhuRef)
            if (ahuRefIndex > -1) {
                this.versionDetails[ahuRefIndex]['name'] = x?.name;
                this.versionDetails[ahuRefIndex]['_id'] = x?._id
            } 
        })

        //The below ccuObj variable will be sent to command component, where it helps on operations related to ccu remote update. Version            default:ault: removed here.
        self.ccuObj = this.versionDetails.filter(x => x?._id);
    }

    getDiagObj(equipTags: any, zone = false) {
        return this.buildings?.equips?.filter(equip => equipTags?.every(elem => equip?.markers?.indexOf(elem) > -1));
    }

    toggleFloorPlan(){
        this.isHighlighted = !this.isHighlighted;
    }

    checkNotesCertificationAccess() {
        this.showNotes = this.authService.isUserAllowedToCheck('notes');
    }

    async getNotesList() {
        this.notesList = await this.notesService.fetchNotes(this.siteId, "", ["pinned"], [], null);
        if (this.notesList?.length) {
            this.notesList = this.notesList.filter(note => !note.archived);
            let notes = this.notesService.checkForZoneCcuReName(this.notesList, this.allZones, this.allCCU)
            if (notes?.length > 0) {
                this.notesList = notes
            }
            if (this.tunerLevel == 'zone') {
                this.notesList = this.notesList.filter(note => note.taggedZones.length)
            }
            if (this.tunerLevel == 'system') {
                this.notesList = this.notesList.filter(note => note.taggedCcus.length)
            }
            this.filteredNotesList = this.notesService.sortNotesList(this.notesList);

             this.notesService.getHierarchy(this.siteId).toPromise().then(siteHierarchy => {
                this.filteredNotesList = this.notesService.addTooltip(this.filteredNotesList, siteHierarchy);
              }).catch((err) => {
                this.alertService.error("Failed to fetch CCU and Zone hierarchy.");
              })

        } else {
            this.filteredNotesList = [];
        }
    }

    checkUserCertificationAccess(view): boolean {
        return this.facHelperService.checkCertification(view,this.showRemoteAccess);
    }
    ngOnDestroy() {
        const self = this;
        Object.keys(self.subscriptions).forEach(e => {
            self.subscriptions[e].unsubscribe();
        });
    }

}
