<div class="systemoperations-wrapper">
    <div class="systemoperations-wrapper__nav" *ngIf="skeletonContentLoaded">
        <div class="systemoperations-wrapper__nav__searchbar" *ngIf="!showEquipPaired">
            <input #ccuSearch type="text" id="inputCcuName" placeholder="Search" title="CCU Name" (keyup)="searchCcu(ccuSearch.value)"><i
              class="fas fa-search" autocomplete="off" aria-hidden="true"></i>
          </div>
        <ul class="systemoperations-wrapper__nav__ccuList">
            <li *ngFor="let ccu of siteCcus" (click)="selectCcu(ccu, $event)" [class.highlight]="ccu.active" [title]="ccu.dis">
                <span class="notranslate">{{ ccu.dis }}</span>
                <div class="data-values-ctnr">
                    <div>
                        CO2: {{cO2AllCCu[ccu.id]?? 'N/A'}}
                    </div>
                    <div>
                        Humidity: {{averageHumidity[ccu.id] ?? 'N/A'}}
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <!--Skeleton For sys operation-->

    <div class="systemoperations-wrapper__nav" *ngIf="!skeletonContentLoaded">
            <ul>
                <li *ngFor="let ccu of mockBuildings">
                        <div class="skeleton-zonename">
                            <ngx-skeleton-loader ></ngx-skeleton-loader>
                        </div>
                        <div class="skeleton-currenttemp" >
                            <span >
                                <ngx-skeleton-loader ></ngx-skeleton-loader>
                            </span>
                        </div>
                       
                </li>
            </ul>
        </div>
    <div class="systemoperations-wrapper__content"  *ngIf="ccuSetting && ccuSetting.occupancyStatus != null; else contentSkeleton">
        <div class="ccu-content">
        <div class="systemoperations-wrapper__content--oaoArc align-item" *ngIf="OaoShow && ccuSetting.oao" [ngClass]="{'pointerDisable': isVofm}">
            <div>
                <img title="bisect" src="../../../../../assets/images/arcBisect.svg" alt="">
            </div>
            <ui-oaoArc [options]="oaoArcOptions" [value]=oaoArcValue style="display: block;
              margin-left: auto;
              margin-right: auto;text-align: center;">
            </ui-oaoArc>
            <div class="systemoperations-wrapper__content--oaoArc--limits">
                <div>0 PPM</div>
                <div>2,000 PPM</div>
            </div>
           <div class="d-flex oao-text">
                <div>OAO</div>
                <div [class.online]="isOAOOnline()" class="status"></div>
           </div>
           
        </div>
        <ul>
            <!-- The following block is only excuted for default systyem profile-->
            <li *ngIf="onSystemMode">
                <span>CONDITIONING</span>
                <label [ngClass]="{'pointerDisable': isVofm}">
                    <input type="radio" [class.checked]="modeList[0] == 'OFF'" name="condition" [value]="modeList[0]"
                        (click)="onSystemModeChange($event, modeList[0])" [ngModel]="modeList[0]" [checked]="true"
                        id="{{'radio-'+ modeList[0].toLowerCase()}}" disabled />
                    <span class="radiobtn"></span>
                    {{ modeList[0] }}
                </label>
            </li>
            <!-- The following block is  excuted for  all the system profiles based on the reconfig selection condition mode will change -->
            <li *ngIf="ccuSetting['systemMode']?.hasOwnProperty('val')" [ngClass]="{'pointerDisable': isVofm}">
                <span>CONDITIONING</span>
                <ng-container *ngFor="let mode of modeList;let i=index">
                    <label *ngIf="isShowCMmode(i)">
                    <input [class.checked]="modeList[ccuSetting.systemMode.val] == mode" type="radio" name="condition" [value]="mode" (click)="onSystemModeChange($event, mode)"
                         [checked]="modeList[ccuSetting.systemMode.val] == mode"
                        id="{{'radio-'+ mode.toLowerCase()}}"/>
                    <span class="radiobtn"></span>
                    {{ mode }}
                    </label>
                </ng-container>
            </li>

           
            <li *ngIf="ccuSetting.occupancyStatus">
                <span>OCCUPANCY STATUS</span>
                <ul class="systemoperations-wrapper__content--move-right occupancyStatus">
                    <li id="occupancy-status" [innerHTML]="isDataLoaded(ccuSetting.occupancyStatus.val, true)"></li>
                </ul>
            </li>
            <li *ngIf="ccuSetting.equipStatus">
                <span>EQUIPMENT STATUS</span>
                <ul class="systemoperations-wrapper__content--move-right">
                    <li id="equipment-status" [innerHTML]="ccuSetting.equipStatus.val"></li>
                </ul>
            </li>
            <li *ngIf="ccuSetting && ccuSetting['cloudLastUpdated']">
                <span>LAST UPDATED</span>
                <ul class="systemoperations-wrapper__content--move-right occupancyStatus">
                    <li id="last-updated" [innerHTML]="dateFormat(ccuSetting['cloudLastUpdated'].val)"></li>
                </ul>
            </li>

            <li *ngIf="ccuSetting.demandResponseActivation">
                <ul>
                    <span>
                      Demand Response Active
                    </span>
                    <li>
                      <mat-slide-toggle id="demandResponseActivation" #demandResponseActivation [(ngModel)]="ccuSetting.demandResponseActivation.val"
                      (change)="onToggleBtnChange($event, 'demandResponseActivation')"></mat-slide-toggle>
                    </li>
                </ul>
            </li>

            <li *ngIf="OaoShow && ccuSetting && ccuSetting['oaoLastUpdated']">
                <span>OAO LAST UPDATED</span>
                <ul class="systemoperations-wrapper__content--move-right occupancyStatus">
                    <li id="oao-last-updated" [innerHTML]="dateFormat(ccuSetting['oaoLastUpdated'].val)"></li>
                </ul>
            </li>


          <!-- Section is only for DAB and VAV External AHU-->
          <ul class="dab_external_ahu" *ngIf="(systemReconfigProfileName == 'vavExternalAHUController') || (systemReconfigProfileName == 'dabExternalAHUController')">
            <li class="dab_external_ahu_point" *ngIf="ccuSetting?.dualSetpointControlEnable?.val == 0">
              <div class="label">Supply Airflow Temperature:</div>
              <div class="pointdata">Current:</div>
              <div class="value" *ngIf="ccuSetting?.supplyAirflowTempCurrent?.hasOwnProperty('val')">
                {{ccuSetting?.supplyAirflowTempCurrent?.val ? ccuSetting?.supplyAirflowTempCurrent?.val : '-'}}
              </div>
              <div class="pointdata">Setpoint:</div>
              <div class="value" *ngIf="ccuSetting?.supplyAirflowTempSetpoint?.hasOwnProperty('val')">
                {{ccuSetting?.supplyAirflowTempSetpoint?.val ? ccuSetting?.supplyAirflowTempSetpoint?.val : '-'}}
              </div>
            </li>


            <li class="dab_external_ahu_point">
              <div class="label">Duct Static Pressure:</div>
              <div class="pointdata">Current:</div>
              <div class="value" *ngIf="ccuSetting?.ductStaticPressureCurrent?.hasOwnProperty('val')">
                {{ccuSetting?.ductStaticPressureCurrent?.val ? ccuSetting?.ductStaticPressureCurrent?.val : '-'}}
              </div>
          
              <div class="pointdata">Setpoint:</div>
              <div class="value" *ngIf="ccuSetting?.ductStaticPressureSetPoint?.hasOwnProperty('val')">
                {{ccuSetting?.ductStaticPressureSetPoint?.val ? ccuSetting?.ductStaticPressureSetPoint?.val : '-'}}
              </div>
            </li>

            <li class="dab_external_ahu_point" *ngIf="ccuSetting?.dualSetpointControlEnable?.val == 1">
              <div class="label">Supply Airflow Temperature:</div>
              <div class="pointdata">Current:</div>
              <div class="value" *ngIf="ccuSetting?.supplyAirflowTempCurrent?.hasOwnProperty('val')">
                {{ccuSetting?.supplyAirflowTempCurrent?.val?ccuSetting?.supplyAirflowTempCurrent?.val : '-'}}
              </div>
              <div class="dav_damper_label">Operating Mode:</div>

              <div class="cooling_set_point_value" *ngIf="ccuSetting?.operatingMode?.hasOwnProperty('val')">
                {{ 
                  ccuSetting?.operatingMode?.val === 0 ? 'Off' :
                  ccuSetting?.operatingMode?.val === 1 ? 'Cooling' :
                  ccuSetting?.operatingMode?.val === 2 ? 'Heating' :
                  ccuSetting?.operatingMode?.val === 3 ? 'Temp Dead' :
                  '-'
                }}
              </div>
            </li>
            <li  class="dab_external_ahu_point" *ngIf="ccuSetting?.dualSetpointControlEnable?.val == 1">
              <div class="label"></div>
              <div  class="dav_damper_label">Heating Setpoint: </div>
              <div  class="cooling_set_point_value">{{ccuSetting?.airTempHeatingSp?.val ? ccuSetting?.airTempHeatingSp?.val : '-'}}</div>

              <div   class="dav_damper_label">Cooling Setpoint:</div>
              <div class="value">{{ccuSetting?.airTempCoolingSp?.val ? ccuSetting?.airTempCoolingSp?.val : '-'}}</div>
            </li>

            <li class="dab_external_ahu_point" *ngIf="ccuSetting?.dcvDamper?.val">
              <div class="dav_damper_label">DCV Damper:</div>
              <div class="dav_damper_pointdata"> {{ccuSetting?.dcvDamper?.val ? ccuSetting?.dcvDamper?.val : '-'}} </div>
            </li>
          </ul>

            <!--
                VAV DAB Advanced AHU

                1. Duct Static Pressure (DSP) based Fan Control:
                This refers to controlling the fan based on the static pressure within the duct system.
                It ensures the system operates efficiently by adjusting fan speed according to the duct's pressure.

                2. Supply Air Temperature Control:
                This involves controlling the temperature of the supplied air. However, there's a dynamic aspect to it 
                based on reconfiguration selection.

                The following parameters need to be checked dynamically for DSP and Supply Air Temperature Control:
                    - If DSP1 is selected: Use supplyAirTemperature1, supplyAirTemperature2, supplyAirTemperature3.
                    - If Average Pressure is selected: Use avgPressure.
                    - If Minimum Pressure is selected: Use minPressure.
                    - If Maximum Pressure is selected: Use maxPressure.

                Similarly, with Duct Static Pressure point:
                    - Based on the user intent, dynamically adjust supply air temperature based on specific configurations 
                    that provide values to be displayed.
            -->
            <ul class="dab_external_ahu" *ngIf="systemReconfigProfileName == 'vavAdvancedHybridAhuV2' || systemReconfigProfileName == 'dabAdvancedHybridAhuV2'">

                <!-- Duct Static Pressue-->
                <li class="dab_external_ahu_point" *ngIf="ccuSetting?.hasOwnProperty('pressureBasedFanControl')">
                    <div class="label">Duct Static Pressure:</div>
                    <div class="pointdata">Current:</div>
                    <div class="value" *ngIf="ccuSetting?.pressureBasedFanControl?.hasOwnProperty('val')">
                        {{ getDischargeAirflowTempValue(ccuSetting) }}
                    </div>
                
                    <div class="pointdata" *ngIf="ccuSetting?.hasOwnProperty('ductStaticPressureSetPoint')">Setpoint:</div>
                    <div class="value" *ngIf="ccuSetting?.ductStaticPressureSetPoint?.hasOwnProperty('val')">
                        {{ccuSetting?.ductStaticPressureSetPoint?.val ?? '-'}}
                    </div>
                </li>
            
                <!-- Supply Airflow Temperature-->
                <li class="dab_external_ahu_point"
                    *ngIf="shouldDisplaySupplyAirTemp()">
                    <div class="label">Supply Airflow Temperature:</div>
                    <div class="pointdata">Current:</div>
                    <div class="value">
                        {{getSupplyAirflowTempValue(ccuSetting)}}
                    </div>
                    <div class="dav_damper_label" *ngIf="ccuSetting?.hasOwnProperty('advancedAhuOperatingMode')">Operating Mode:
                    </div>
            
                    <div class="cooling_set_point_value" *ngIf="ccuSetting?.advancedAhuOperatingMode?.hasOwnProperty('val')">
                        {{
                        getOperatingMode(ccuSetting)
                        }}
                    </div>
                </li>
            
                <li class="dab_external_ahu_point"
                    *ngIf="(shouldDisplaySupplyAirTemp() && (ccuSetting.hasOwnProperty('airTempHeatingSp'))) || ( shouldDisplaySupplyAirTemp() && (ccuSetting.hasOwnProperty('airTempCoolingSp')))">
            
                    <div class="label"></div>
                    <div class="dav_damper_label" *ngIf="ccuSetting.hasOwnProperty('airTempHeatingSp')">Heating Setpoint: </div>
                    <div class="cooling_set_point_value" *ngIf="ccuSetting.hasOwnProperty('airTempHeatingSp')">
                        {{ccuSetting?.airTempHeatingSp?.val ? ccuSetting?.airTempHeatingSp?.val : '-'}}
                    </div>
            
            
                    <div class="dav_damper_label" *ngIf="ccuSetting.hasOwnProperty('airTempCoolingSp')">Cooling Setpoint:</div>
                    <div class="value" *ngIf="ccuSetting.hasOwnProperty('airTempCoolingSp')">{{ccuSetting?.airTempCoolingSp?.val ?
                        ccuSetting?.airTempCoolingSp?.val : '-'}}</div>
                </li>
                <li class="dab_external_ahu_point" *ngIf="ccuSetting?.dcvDamperControl?.val">
                    <div class="dav_damper_label">DCV Damper:</div>
                    <div class="dav_damper_pointdata"> {{ccuSetting?.dcvDamperControl?.val ? ccuSetting?.dcvDamperControl?.val :
                        '-'}}
                    </div>
                </li>
            
            </ul>
            <!---->
              
            <li class="systemoperations-wrapper__content--humidity">
                <ul *ngIf="ccuSetting.minHumidity?.hasOwnProperty('val')">
                    <li>Min Inside Humidity:</li>
                    <li [ngClass]="{'disableAndBlur': isVofm}">
                        <mat-select #MinHumidity [(ngModel)]="ccuSetting.minHumidity.val"
                            (selectionChange)="onNumberTickerChange($event, 'minHumidity')">
                            <mat-option *ngFor="let number of humdityValues;let i = index" id="{{'minHumidityCCU-'+number}}" [value]="i">{{number}}%</mat-option>
                        </mat-select>
                    </li>
                </ul>
                <ul *ngIf="onSystemMode">
                    <li>Min Inside Humidity:</li>
                    <li [ngClass]="{'disableAndBlur': isVofm}">
                        <select [(ngModel)]="defaultMinHumidity" disabled>
                            <option *ngFor="let number of humdityValues;let i = index" id="{{'minHumiditySystem-'+number}}" [value]="i">
                                {{defaultMinHumidity}}%</option>
                        </select>
                    </li>
                </ul>

                <ul *ngIf="ccuSetting.maxHumidity?.hasOwnProperty('val')">
                    <li>Max Inside Humidity:</li>
                    <li [ngClass]="{'disableAndBlur': isVofm}">
                        <mat-select #MaxHumidity [(ngModel)]="ccuSetting.maxHumidity.val"
                            (selectionChange)="onNumberTickerChange($event, 'maxHumidity')">
                            <mat-option *ngFor="let number of humdityValues;let i = index" id="{{'maxHumidityCCU-'+number}}" [value]="i">{{number}}%</mat-option>
                        </mat-select>
                    </li>
                </ul>
                <ul *ngIf="onSystemMode">
                    <li>Max Inside Humidity:</li>
                    <li [ngClass]="{'disableAndBlur': isVofm}">
                        <select [(ngModel)]="defaultMaxHumidity" disabled>
                            <option *ngFor="let number of humdityValues;let i = index" id="{{'maxHumiditySystem-'+number}}" [value]="i">
                                {{defaultMaxHumidity}}%</option>
                        </select>
                    </li>
                </ul>
            </li>
           
            <li *ngIf="ccuSetting">
                <puc-intrinsic-schedules class="heatmap-fs-schedule"
                  #intrinsicRef
                  [siteTz]="siteTz"
                  [revampedScheduler]="showSchedulerOccupancy"
                  [scheduleData]="intrinsicScheduleData"
                  >
                </puc-intrinsic-schedules>
            </li>
            <!--Epidemic Mode-->
            <div *ngIf="OaoShow" class="systemoperations-wrapper__content__epidemice-mode">
                <h4>Epidemic Mode Settings</h4>
                <ul class="systemoperations-wrapper__content--move-right">
                    <li *ngIf="ccuSetting.smartPrepurge">
                        <ul>
                            <li>
                                Smart Pre Purge
                            </li>
                            <li [ngClass]="{'disableAndBlur': isVofm}">
                                <mat-slide-toggle #smartPrepurge [(ngModel)]="ccuSetting.smartPrepurge.val"
                                (change)="onToggleBtnChange($event, 'smartPrepurge')"></mat-slide-toggle>
                            </li>
                        </ul>
                    </li>
                    <li *ngIf="ccuSetting.smartPostpurge">
                        <ul>
                            <li>
                                Smart Post Purge
                            </li>
                            <li [ngClass]="{'disableAndBlur': isVofm}">
                              <mat-slide-toggle #smartPostpurge [(ngModel)]="ccuSetting.smartPostpurge.val"
                               (change)="onToggleBtnChange($event, 'smartPostpurge')"></mat-slide-toggle>
                            </li>
                        </ul>
                    </li>
                    <li *ngIf="ccuSetting.enhancedVentilation">
                        <ul>
                            <li>
                                Enhanced Ventilation
                            </li>
                            <li [ngClass]="{'disableAndBlur': isVofm}">
                                <mat-slide-toggle #enhancedVentilation
                                [(ngModel)]="ccuSetting.enhancedVentilation.val"
                                (change)="onToggleBtnChange($event, 'enhancedVentilation')"></mat-slide-toggle>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
           
        </ul>
        <div class="profile-position" *ngIf="showBtu && ccuSetting && ccuSetting.occupancyStatus != null">
            <div class="d-flex">
                <h4 id="btuMeterType" class="sys-lvl-modbus">{{btuMeterType}}</h4>
                <div [class.online]="isOnline('btu')" class="status margin-top-1"></div>
            </div>
            <ng-container *ngIf="hideDisplayUIBtuText" [ngClass]="{'pointerDisable': isVofm}">
                <puc-device-settings class="profileName" *ngIf="ccuSetting && ccuSetting['btu']"
                [settings]="ccuSetting['btu']" [tags]="btuTagsProfile" [siteTimeZone]="siteTz" [isVofm] = "isVofm"></puc-device-settings>
            </ng-container>
    
            <div *ngIf="!hideDisplayUIBtuText">
                <ul class="fs-ccu-settings-wrapper__content--move-right occupancyStatus m-l-20">
                  <li>None Of The Parameter Is Enabled In CCU For Display. Please Enable In CCU To View  1.</li>
                </ul>
            </div>
        </div>
    
        <div class="profile-position" *ngIf="showEmr && ccuSetting && ccuSetting.occupancyStatus != null">
                <div class="d-flex">
                    <h4 id="emrMeterType" class="sys-lvl-modbus">{{emrMeterType}}</h4>
                    <div [class.online]="isOnline('emr')" class="status margin-top-1"></div>
                </div>
                <ng-container *ngIf="hideDisplayUIText" [ngClass]="{'pointerDisable': isVofm}">
                    <puc-device-settings class="profileName" *ngIf="ccuSetting && ccuSetting['emr']"
                    [settings]="ccuSetting['emr']" [tags]="btuTagsProfile" [siteTimeZone]="siteTz" [isVofm] = "isVofm"></puc-device-settings>
                </ng-container>
                <div *ngIf="!hideDisplayUIText">
                    <ul class="fs-ccu-settings-wrapper__content--move-right occupancyStatus m-l-20">
                    <li>None Of The Parameter Is Enabled In CCU For Display. Please Enable In CCU To View 2.</li>
                    </ul>
                </div>
        </div>


        <div class="profile-position" *ngIf="showModbus">
            <div class="d-flex">
              <h4 class="sys-lvl-modbus">{{modbusType}}</h4>
              <div [class.online]="isOnline('modbus')" class="status margin-top-1-2"></div>
            </div>
            <div *ngIf="hideDisplayUIModbusText" class="p-l-10" [ngClass]="{'pointerDisable': isVofm}">
                <puc-device-settings class="profileName" *ngIf="ccuSetting && ccuSetting['modbus']"
                [settings]="ccuSetting['modbus']" [tags]="btuTagsProfile" [siteTimeZone]="siteTz" [isVofm] = "isVofm"></puc-device-settings>
            </div>

            <div class="display-ui-status" *ngIf="!hideDisplayUIModbusText">
                  <span class="m-l-10" >None Of The Parameter Is Enabled In CCU For Display. Please Enable In CCU To View. 3</span>
            </div>
        </div>
    </div>
      <div *ngIf="ccuSetting && checkCertificationAccess('remote-ccu-access')">
            <div class="header-container">
                <div class="rmt-header">REMOTE ACCESS</div>
            </div>
        </div>
        <div class="rmt-container" *ngIf="ccuSetting && checkCertificationAccess('remote-ccu-access')">
            <puc-remote-access-portal [siteId]="siteRef" [ccuId]="ccuId" appContext="system-equip" [targetEntityId]="ahuRef"></puc-remote-access-portal>
        </div> 
    </div>

    <div class="showEquipPaired m-t-100" *ngIf="showEquipPaired">
        <h3> No CCU'S  are paired </h3>
    </div>
    <ng-template #contentSkeleton>
            <div class="systemoperations-wrapper__content " *ngIf="ccuId">
                <ul class="ccu-content">
                    <li>
                        <span> <ngx-skeleton-loader [theme]="{ 'border-radius': '0', width: '150px'}"></ngx-skeleton-loader></span>
                        <label>
                                <ngx-skeleton-loader  *ngFor="let repeat of [0,1,2,3]" ></ngx-skeleton-loader>
    
                        </label>
                    </li>
        
                    <li>
                        <span><ngx-skeleton-loader [theme]="{ 'border-radius': '0', width: '150px'}"></ngx-skeleton-loader></span>
                        <ul>
                            <li><ngx-skeleton-loader></ngx-skeleton-loader></li>
                 
                        </ul>
                    </li>
    
                    <li>
                            <span><ngx-skeleton-loader [theme]="{ 'border-radius': '0', width: '150px'}"></ngx-skeleton-loader></span>
                            <ul>
                                <li><ngx-skeleton-loader></ngx-skeleton-loader></li>
                     
                            </ul>
                   </li>
    
                    <li>
                        <div>
                            <span><ngx-skeleton-loader [theme]="{ 'border-radius': '0', width: '150px'}"></ngx-skeleton-loader></span>
    
                            <ngx-skeleton-loader ></ngx-skeleton-loader>
                            <span><ngx-skeleton-loader [theme]="{ 'border-radius': '0', width: '150px'}"></ngx-skeleton-loader></span>
                        </div>
                    </li>
        
                    <li class="systemoperations-wrapper__content--humidity">
                            <div id="skeleton-vacation">
                                    <div class="skeleton-vacation-value" >
                                      <div class="skeleton-vacation-width"   *ngFor="let repeat of [0,1,2]">
                                          <ngx-skeleton-loader   ></ngx-skeleton-loader>
                                      </div>
                                    </div>
                            </div>
                     
                    </li>
                </ul>
            </div>
    </ng-template>
</div>