import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { AuthenticationService } from '@75f/portal-ui-components';
import { EasyStreetService } from '../../services/easystreet.service';

@Component({
  selector: 'fs-easy-street-metrics',
  templateUrl: './easy-street-metrics.component.html',
  styleUrls: ['./easy-street-metrics.component.scss']
})
export class EasyStreetMetricsComponent implements OnInit, OnChanges {

  @Input() ccuData: any;
  @Input() selectedSite: any;
  isLoading = true;
  placeholderMessage: any;
  timezone: any;
  tz!: any;
  ccuValidantionMsg = 'No CCU found';
  columnsToDisplay: string[] = ['ccuName', 'score'];
  skeletonRows = Array(7);
  tableData: any[] = [];
  ccuList: any[] = [];
  // Mock data
  ELEMENT_DATA = [
    { ccuName: 'CCU1', score: 85 },
    { ccuName: 'CCU2', score: 90 }
  ];
  constructor(private authService: AuthenticationService,
    private easystreetService: EasyStreetService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['ccuData'] && changes['ccuData'].currentValue) {
      this.getCCUCheckoutReport();
      // this.ccuList = change.ccuData.currentValue;
      // this.originalTableData = this.ccuList;
      // this.dataSource = new MatTableDataSource(this.ccuList);
      // this.dataSource.sort = this.sort;
      // this.isLoading = false;
    }
  }

  ngOnInit(): void {}

  getFormattedColumnName(columnName: string) {
    switch (columnName) {
      case 'ccuName':
        return 'CCU Name';
      case 'score':
        return 'Score';
      case 'run':
        return 'Run';
      default:
        return columnName;
    }
  }

  runTest(selectedCcu: any) {
    console.log('Run test');
  }

  runAllTests() {
    console.log('Run all tests');
  }

  get isValidCertificationLevel(): boolean {
    return ['L1', 'L2', 'L3'].includes(this.authService.certificationLevel);
  }

  getCCUCheckoutReport() {
    // this.isLoading = true;
    this.isLoading = false;
    this.tableData = [];
    if(this.ccuData && this.ccuData.length) {
      // Fetch CCU ID from CCU Checkout History API for each CCU
      this.ccuData.forEach((ccu: any) => {
        this.easystreetService.getCCUCheckoutHistory(this.selectedSite, ccu.ccuId).subscribe((data: any) => {
          console.log('CCU Checkout History Data:', data);
          if(data && data.runId) {
            const groupBy = true;
            this.easystreetService.getCCUCheckoutReportById(this.selectedSite, ccu.id, data.runId, groupBy).subscribe((ccuCheckoutReport: any) => {
              console.log('Site Checkout Details Data:', ccuCheckoutReport);
              if(ccuCheckoutReport && ccuCheckoutReport.ccuId) {
                this.tableData.push(ccuCheckoutReport);
              }
              this.isLoading = false;
  
            });
          }
        });
      });
    }
  }

}
