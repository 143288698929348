import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
enum PartnerName {
  Carrier = 'Carrier',
  Airoverse = 'Airoverse',
  seventyFiveF = '75F'
}

// Enum for the Support label
enum Label {
  Support = 'Support',
}
@Component({
  selector: 'fs-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  version = '';
  contactInfo: any;
  copyright: string;
  versionText: string;
  partnerName: string;
  constructor() { }

  ngOnInit(): void {
    this.versionText = environment.portalVersionText;
    this.version = environment.version;
    this.contactInfo = environment.contact;
    this.partnerName = environment.partnerName;
    this.copyright = `Copyright &copy; ${new Date().getFullYear()} ${environment.copyright}`
  }

  // Function to check if the partner is Carrier and the label is Support
  checkCarrierSupport(label){
    return this.partnerName === PartnerName.Carrier && label === Label.Support;
  }

}
