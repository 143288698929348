<section class="building-option-sections p-l-20 p-t-30 p-r-20">
    <div class="row">
        <div class="col-3 settings-nav">
            <ul class="list-none text-uppercase">
                <li *ngFor="let submenu of submenus;let i =index;" class="m-20-a">
                    <a *ngIf="checkUserCertificationAccess(submenu.key)" id="{{'building-options-'+i}}" [routerLink]="submenu?.path" class="{{submenu.key}}" [ngClass]="{'margin_left': submenu?.key == 'building-options'}" [routerLinkActive]="['active']">
                        {{submenu?.label}}
                    </a>
                </li>
            </ul>
        </div>
        <div class="col-9">
            <h4><span class="section-label text-uppercase">Buildings</span></h4>
            <form name="remoteOptionsBuildings" class="p-l-40">
                <div class="row">
                    <div class="col-12">
                        <p><span class="installer-label">Temperature Limits</span><br/>
                        <span class="installer-label-caption">Building limits specify the max/min temperatures before alerts will be generated and emergency heating/cooling will occur.</span>
                        </p>
                    </div>
                    <div #tempSliderSection class="col-10" >
                        <puc-remote-installer-options-slider #remoteInstaller></puc-remote-installer-options-slider>
                    </div>
                </div>
            </form>
            <!------------------------------------------- Remote Command of Remote Installer ----------------------------->
           <puc-backfill-options-layout [siteRefForBackFill]="siteRef">
            </puc-backfill-options-layout>
            <!------------------------------------------------------------------------------------------------------------>
        </div>
    </div>
</section>
