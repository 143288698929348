export const environment = {
    version: '3.3.0',
    production: true,
    helpCenter: "https://support.75f.io",
    copyright: '75F, Inc. All right reserved.',
    partnerName: '75F',
    portalVersionText: 'Facilisight Version',
    privacyStatement: 'https://www.75f.io/legal#privacynotice',
    termsOfService: 'https://www.75f.io/legal#terms-of-service',
    partner: false,
    contact: [
        { "label": 'support', "value": 'support@75f.io', 'target': 'mailto:support@75f.io', 'icon': 'fas fa-envelope' },
        { "label": 'India Support', "value": '1800 121 4575', 'target': 'tel:1800 121 4575', 'icon': 'fas fa-phone-alt' },
        { "label": 'US Support', "value": '888 612 7575', 'target': 'tel:888 612 7575', 'icon': 'fas fa-phone-alt' },
    ],
    config: {
        "stage": "dev",
        "appName": "FACILISIGHT",
        "partnerName": "75F",
        "filestorageUrl": "https://filestorage.dev.service.75f.io/",
        "haystackUrl": "https://silo.dev.service.75f.io/",
        "messagingUrl": "https://messaging.dev.service.75f.io/",
        "omnitrixUrl": "https://configuration.dev.service.75f.io/",
        "alertsUrl": "https://alerts.dev.service.75f.io/",
        "alertBuilder": "https://alert-builder.dev.service.75f.io/",
        "auth": {
            "caretakerUrl": "https://caretaker.dev.service.75f.io/api/v1",
            "gatekeeperUrl": "https://gatekeeper.dev.service.75f.io/api/v1",
        },
        "cpmUrl": "https://cpm.dev.app.75f.io/",
        "NamedSchUrl": "https://gateway-apis.dev.service.75f.io",
        "firebaseConfig": {
            "apiKey": "AIzaSyBC5RaWi3t0_ViWDhti6v2id_IsV1kfDzM",
            "projectId": "alertsv2-dev",
            "messagingSenderId": "536667168071",
            "appId": "1:536667168071:web:8da4a3d8e1271302dcc062"
        },
        "pamUrl": "https://pam-dev.75f.io/",
        "pasUrl": "https://portfolio-analytics.dev.service.75f.io/",
        "customDashboardUrl": "https://dashboards-dev.75f.io/",
        "tagsUrl": "https://silo.dev.service.75f.io/v2/sitePointTags",
        "siteSequencerUrl": "https://site-sequencer-dev.75f.io",
        "siteManagerUrl": "https://thankful-plant-00ea35110.1.azurestaticapps.net",
        "siteManagerServiceUrl": "https://site-manager.dev.service.75f.io/",
        "siteSequencerRunnerUrl": "https://seq-runner.dev.service.75f.io",
        "analyticsConfig": {
            "analyticsKey": "UA-227273352-1",
            "analyticsUrl": "https://www.googletagmanager.com/gtag/js?id="
        },
        'devCycleClientId': "dvc_client_23b64259_75f9_4434_bbb5_bc095bb69b45_a1e3166",
        "notesUrl": "https://notes-api.dev.service.75f.io/",
        "notesAzureUrl":"https://notes75f.blob.core.windows.net",
        "summaryTableUrl": "https://table-maker-api.dev.service.75f.io/",
        "cpmServiceUrl": "https://chiller-plant.dev.service.75f.io/",
        "ccuVersionUrl": "https://version-management-api.dev.service.75f.io",
        "OTA_STRING_VALIDATION": "RENATUS_CCU_dev_",
        "OTA_STRING_VALIDATION_NEW": "CCU_dev_",
        "OTA_REMOTEAPP_STRING_VALIDATION" : "RemoteApp_dev_",
        "OTA_HOMEAPP_STRING_VALIDATION" : "HomeApp_dev_",
        "OTA_BACAPP_STRING_VALIDATION": "BACapp_dev_",
        "energyConfigUrl": "https://portfolio-analytics.dev.service.75f.io/",
        "chatServiceUrl": "https://chat.dev.service.75f.io/",
        "chatSupportUrl": "https://support.75f.io/hc/en-us/articles/22524392931603-Saffron-AI-Chat-Bot",
        "appDisplayName":"Facilisight",
        "remoteAccessPortalUrl": "https://remote-access-portal.dev.app.75f.io/",
        "qrCodeBaseUrl": "https://m-dev.75f.io/",
        "siteAnalyticsUrl": "https://bas-75f-service-dev.azurewebsites.net"
    }
};
